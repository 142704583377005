/* src/components/Contact.css */
#contact {
    background-color: #f4f4f9;
    padding: 100px 20px;
    text-align: center;
  }
  
  #contact h2 {
    color: #7289da;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  #contact p {
    color: #333;
    font-size: 1.2em;
    max-width: 600px;
    margin: 0 auto 40px auto;
  }
  
  .contact-form {
    max-width: 500px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .contact-form input, 
  .contact-form textarea {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .contact-form input:focus, 
  .contact-form textarea:focus {
    border-color: #7289da;
    outline: none;
  }
  
  .contact-form button {
    padding: 12px 20px;
    background-color: #7289da;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
  }
  
  .contact-form button:hover {
    background-color: #5865f2;
  }
  
  /* src/components/Contact.css */
.hidden {
    display: none;
  }
  