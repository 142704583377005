/* src/components/About.css */
#about {
    background-color: #ffffff;
    padding: 100px 20px;
    text-align: center;
  }
  
  #about h2 {
    color: #7289da;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  #about p {
    color: #333;
    font-size: 1.2em;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
  