/* src/components/Header.css */
header {
  background-color: #23272a;
  color: white;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  flex-shrink: 0; /* Ensures the logo doesn't shrink smaller than the defined size */
}

.logo img {
  height: 80px; /* Set an appropriate height */
  width: auto;
  max-width: 100%;
}

nav {
  display: flex;
  gap: 20px; /* Adds space between the nav links */
  flex-grow: 1; /* Allows the navigation menu to take up remaining space */
  justify-content: flex-end; /* Aligns the menu to the right */
}

nav a {
  color: white;
  padding: 14px 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}

nav a:hover {
  background-color: #7289da;
  border-radius: 4px; /* Optional: Rounded corners for a nicer hover effect */
}
