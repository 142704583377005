/* src/components/Home.css */
#home {
    background-color: #f4f4f9;
    padding: 100px 20px; /* Add some generous padding for spacing */
    text-align: center;
  }
  
  #home h2 {
    color: #7289da;
    font-size: 2.5em; /* Large and prominent heading */
    margin-bottom: 20px;
  }
  
  #home p {
    color: #333;
    font-size: 1.2em; /* Slightly larger than default paragraph size */
    max-width: 800px; /* Restrict the width for better readability */
    margin: 0 auto;
    line-height: 1.6;
  }
  