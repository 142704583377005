/* src/styles.css */
/* src/styles.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h2 {
  font-family: 'Helvetica', sans-serif;
  color: #7289da;
}

p {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
}

@media (max-width: 768px) {
    nav {
      flex-direction: column;
    }
  
    .services-list {
      flex-direction: column;
      gap: 20px;
    }
  
    header {
      flex-direction: column;
    }
  }
  